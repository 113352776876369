<template>
  <div class="col-12">
    <div class="row justify-content-md-center"></div>

    <div class="row mt-2">
      <div class="ent-title">
        Date de debut de projection
      </div>
      <div v-if="ent.date_proj"
           class="ent-value">
        {{ ent.date_proj[2] + "/" + ent.date_proj[1] + "/" + ent.date_proj[0] }}
      </div>
      <div v-else
           class="ent-value">
        {{ datefr }}
      </div>
      <div class="ent-vt-title">
        <span> Valeur Actuelle Net (VAN)</span>
      </div>
      <div class="ent-vt-value">
        <span v-if="ent.van">{{ Math.round(Number(ent.van)).toLocaleString() }}</span>
        <span v-else>0</span>
      </div>
    </div>

    <div class="row mt-1">
      <div class="ent-title">
        Nombre d'année de projection
      </div>
      <div v-if="ent.nb_annee_proj"
           class="ent-value">
        <input type="number"
               name=""
               id=""
               v-model="ent.nb_annee_proj"
               @change="decount()" />
      </div>
      <div v-else
           class="ent-value">
        0
      </div>
      <div class="ent-vt-title">
        <span> TRI</span>
      </div>
      <div v-if="ent.taux_actualisation && ent.tri <= ent.taux_actualisation"
           class="ent-vt-value bg-danger text-white">
        <span>{{ Math.round(Number(ent.tri)).toLocaleString() }}%</span>
      </div>
      <div v-else-if="ent.taux_actualisation && ent.tri > ent.taux_actualisation"
           class="ent-vt-value bg-success text-white">
        <span>{{ Math.round(Number(ent.tri)).toLocaleString() }}%</span>
      </div>
      <div v-else
           class="ent-vt-value bg-default">
        <span>0%</span>
      </div>
      <div class="col-sm">
        <span v-if="ent.taux_actualisation && ent.tri <= ent.taux_actualisation"
              class="text-danger">
          Projet non rentable
        </span>
        <span v-else-if="ent.taux_actualisation && ent.tri > ent.taux_actualisation"
              class="text-success">
          Projet rentable
        </span>
      </div>
    </div>

    <!-- <div class="row mt-1">
      <div class="ent-title">
        Total investissement
      </div>
      <div class="ent-value">
        0
      </div>
    </div> -->

    <div class="row mt-1">
      <div class="ent-title">
        Fonds propres
      </div>
      <div v-if="ent.fond_propre"
           class="ent-value">
        {{ ent.fond_propre.toLocaleString() }}
      </div>
      <div v-else
           class="ent-value">
        0
      </div>
      <!-- <div class="ent-value">
        0%
      </div> -->
    </div>

    <div class="row mt-1">
      <div class="ent-title">
        Dettes
      </div>
      <div v-if="ent.dette"
           class="ent-value">
        {{ ent.dette.toLocaleString() }}
      </div>
      <div v-else
           class="ent-value">
        0
      </div>
      <!-- <div class="ent-value">
        0%
      </div> -->
    </div>
    <div class="row mt-1">
      <div class="ent-title">
        Service de la dette
      </div>
      <div v-if="ent.service"
           class="ent-value">
        {{ ent.service.toLocaleString() }}
      </div>
      <div v-else
           class="ent-value">
        0
      </div>
      <!-- <div class="ent-value">
        0%
      </div> -->
    </div>
    <div class="row mt-1">
      <div class="ent-title">
        Commission de la LF
      </div>
      <div v-if="ent.commission"
           class="ent-value">
        {{ ent.commission.toLocaleString() }}
      </div>
      <div v-else
           class="ent-value">
        0
      </div>
      <!-- <div class="ent-value">
        0%
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "ResultatEntete",
  props: ["ent", "def"],
  data: () => ({
    values: "",
    nb: 0,
    datefr: ""
  }),
  created() {
    var datebrute = new Date()
    this.datefr = datebrute.getDay() + "/" + (datebrute.getMonth() + 1) + "/" + datebrute.getFullYear()
  },
  watch: {
    ent() {
      if (this.ent) {
        this.values = this.ent
        this.nb = this.ent.nb_annee_proj
        // console.log(this.ent.nb_annee_proj)
      }
    }
  },
  methods: {
    decount() {
      console.log(this.nb)
      this.$emit("nba", this.nb)
    }
  }
}
</script>

<style scoped>
div.ent-title {
  width: 25.5vw;
  padding-left: 1.2vw;
  color: white;
  background: #0097a9;
}
div.ent-value {
  width: 8.1vw;
  padding-left: 0.6vw;
  font-size: 0.8em;
}
div.ent-vt-title {
  width: 16.2vw;
  padding-left: 1.2vw;
  color: white;
  margin-left: 16.2vw;
  background: #0097a9;
}
div.ent-vt-value {
  width: 8.5vw;
  padding-left: 0.5vw;
  margin-left: 0.5vw;
}
div.ent-value input {
  width: 90%;
  padding-left: 0.5vw;
  font-size: 1em;
  border: none;
}
div.ent-value input ::before {
  border: none;
}
/* div[class="col-sm"]:first-child {
  background-color: black;
  color: #ffc720;
  font-size: 13px;
  width:15vw;
}
div[class="col-sm"]:nth-child(2) {
  border-right: 1px solid black;
  text-align: center;
}
div[class="col-sm"]:nth-child(3) {
  border: 1px dashed black;
  text-align: center;
}
div[class="row"] {
  border: 1px solid black;
  margin-bottom: 3px;
} */
</style>
