<template>
  <div class="inner_main">
    <table class="table mt-4">
      <thead>
        <tr v-if="cas.cash_flow">
          <th class="result-title tht-blue">Ressources de depenses</th>
          <th class="tht-blue text-center"
              v-for="(value, csh) in cas.cash_flow"
              :key="csh">{{ value.date }}</th>
        </tr>
        <tr v-else>
          <th class="result-title tht-blue">Ressources de depenses</th>
          <th class="tht-blue"
              v-for="value in 10"
              :key="value">Annee {{ value }}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th class="result-title tht-dark-blue">Remplacement des véhicules amortis</th>
          <template v-if="cas.Remplacement_des_vehicules_amortis">
            <td class="result-value" 
                v-for="(value, rvkey) in cas.Remplacement_des_vehicules_amortis"
                :key="rvkey">{{ Math.round(value.montant).toLocaleString() }}</td>
          </template>
          <template v-else>
            <td class="result-value" 
                v-for="value in 10"
                :key="value">0</td>
          </template>
        </tr>
        <tr>
          <th class="result-title tht-dark-blue">Renouvellement des ordinateurs amortis</th>
          <template v-if="cas.Renouvellement_des_ordinateurs_amortis">
            <td class="result-value" 
                v-for="(value, rekey) in cas.Renouvellement_des_ordinateurs_amortis"
                :key="rekey">{{ Math.round(value.montant).toLocaleString() }}</td>
          </template>
          <template v-else>
            <td class="result-value" 
                v-for="value in 10"
                :key="value">0</td>
          </template>
        </tr>
        <tr>
          <th class="result-title tht-dark-blue">Renouvellement des téléphones fixes amortis</th>
          <template v-if="cas.Renouvellement_des_telephones_fixes_amortis">
            <td class="result-value" 
                v-for="(value, rrkey) in cas.Renouvellement_des_telephones_fixes_amortis"
                :key="rrkey">{{ Math.round(value.montant).toLocaleString() }}</td>
          </template>
          <template v-else>
            <td class="result-value" 
                v-for="value in 10"
                :key="value">0</td>
          </template>
        </tr>
        <tr>
          <th class="result-title tht-dark-blue">Renouvellement du mobilier amorti</th>
          <template v-if="cas.Renouvellement_du_mobilier_amortis">
            <td class="result-value" 
                v-for="(value, rmkey) in cas.Renouvellement_du_mobilier_amortis"
                :key="rmkey">{{ Math.round(value.montant).toLocaleString() }}</td>
          </template>
          <template v-else>
            <td class="result-value" 
                v-for="value in 10"
                :key="value">0</td>
          </template>
        </tr>
        <!-- <tr>
          <th class="result-title tht-dark-blue">Service de la dette</th>
          <td class="result-value" 
              v-for="(service, skey) in services"
              :key="skey">
            {{ Math.round(service).toLocaleString() }}
          </td>
          <template v-if="services.length < cas.cash_flow.length">
            <td class="result-value" 
                v-for="sskey in cas.cash_flow.length - services.length"
                :key="sskey">0</td>
          </template>
        </tr>
        <tr>
          <th class="result-title tht-dark-blue">Commission de la LF</th>
          <td class="result-value" 
              v-for="(commission, cmkey) in commissions"
              :key="cmkey">
            {{ Math.round(commission).toLocaleString() }}
          </td>
          <template v-if="commissions.length < cas.cash_flow.length">
            <td class="result-value" 
                v-for="cmmkey in cas.cash_flow.length - commissions.length"
                :key="cmmkey">0</td>
          </template>
        </tr> -->
        <!-- Le total des depenses d'investissement-->
        <tr class="bg-secondary">
          <th>Dépenses d'investissements</th>
          <template v-if="cas.Depenses_investissements">
            <td class="result-value" 
                v-for="(value, dikey) in cas.Depenses_investissements"
                :key="dikey">{{ Math.round(value.montant).toLocaleString() }}</td>
          </template>
        </tr>

        <!-- Les charges d'exploitations-->
        <tr>
          <!-- Une ligne de separation-->
          <td colspan="11"></td>
        </tr>

        <tr>
          <th class="result-title tht-dark-blue">Achat (carburant,fournitures,etc)</th>
          <template v-if="cas.Achats">
            <td class="result-value" 
                v-for="(value, akey) in cas.Achats"
                :key="akey">{{ Math.round(value.montant).toLocaleString() }}</td>
          </template>
          <template v-else>
            <td class="result-value" 
                v-for="value in 10"
                :key="value">0</td>
          </template>
        </tr>
        <tr>
          <th class="result-title tht-dark-blue">Transport(transport du personnel et des plis,etc)</th>
          <template v-if="cas.Transports">
            <td class="result-value" 
                v-for="(value, tkey) in cas.Transports"
                :key="tkey">{{ Math.round(value.montant).toLocaleString() }}</td>
          </template>
          <template v-else>
            <td class="result-value" 
                v-for="value in 10"
                :key="value">0</td>
          </template>
        </tr>
        <tr>
          <th class="result-title tht-dark-blue">SEA</th>
          <template v-if="cas.SEA">
            <td class="result-value" 
                v-for="(value, sekey) in cas.SEA"
                :key="sekey">{{ Math.round(value.montant).toLocaleString() }}</td>
          </template>
        </tr>
        <tr>
          <th class="result-title tht-dark-blue">SEB</th>
          <template v-if="cas.SEB">
            <td class="result-value" 
                v-for="(value, sbkey) in cas.SEB"
                :key="sbkey">{{ Math.round(value.montant).toLocaleString() }}</td>
          </template>
        </tr>
        <tr>
          <th class="result-title tht-dark-blue">Autres charges</th>
          <template v-if="cas.Autres_charges">
            <td class="result-value" 
                v-for="(value, ackey) in cas.Autres_charges"
                :key="ackey">{{ Math.round(value.montant).toLocaleString() }}</td>
          </template>
          <template v-else>
            <td class="result-value" 
                v-for="value in 10"
                :key="value">0</td>
          </template>
        </tr>
        <tr>
          <th class="result-title tht-dark-blue">Charges de personnel</th>
          <template v-if="cas.Charges_de_personnels">
            <td class="result-value" 
                v-for="(value, cpkey) in cas.Charges_de_personnels"
                :key="cpkey">{{ Math.round(value.montant).toLocaleString() }}</td>
          </template>
          <template v-else>
            <td class="result-value" 
                v-for="value in 10"
                :key="value">0</td>
          </template>
        </tr>
        <tr class="bg-secondary">
          <th>Charges d'exploitation</th>
          <template v-if="cas.Charges_exploitation">
            <td class="result-value" 
                v-for="(value, cexkey) in cas.Charges_exploitation"
                :key="cexkey">{{ Math.round(value.montant).toLocaleString() }}</td>
          </template>
        </tr>
        <!-- provisions et depenses en entretient-->
        <tr>
          <!-- Une ligne de separation-->
          <td colspan="11"></td>
        </tr>
        <tr>
          <th class="result-title tht-dark-blue">Entretien courant</th>
          <template v-if="cas.Entretien_courants">
            <td class="result-value" 
                v-for="(value, ekey) in cas.Entretien_courants"
                :key="ekey">{{ Math.round(value.montant).toLocaleString() }}</td>
          </template>
          <template v-else>
            <td class="result-value" 
                v-for="value in 10"
                :key="value">0</td>
          </template>
        </tr>
        <tr>
          <th class="result-title tht-dark-blue">Provision pour entretient périodique nécessaire</th>
          <template v-if="cas.Provisions_pour_entretien_periodique_necessaires">
            <td class="result-value" 
                v-for="(value, ppkey) in cas.Provisions_pour_entretien_periodique_necessaires"
                :key="ppkey">{{ Math.round(value.montant).toLocaleString() }}</td>
          </template>
          <template v-else>
            <td class="result-value" 
                v-for="value in 10"
                :key="value">0</td>
          </template>
        </tr>
        <tr>
          <th class="result-title tht-dark-blue">Provision pour entretient périodique (part du FER)</th>
          <template v-if="cas.Provisions_pour_entretien_periodique_part_du_FER">
            <td class="result-value" 
                v-for="(value, pkey) in cas.Provisions_pour_entretien_periodique_part_du_FER"
                :key="pkey">{{ Math.round(value.montant).toLocaleString() }}</td>
          </template>
          <template v-else>
            <td class="result-value" 
                v-for="value in 10"
                :key="value">0</td>
          </template>
        </tr>
        <tr class="bg-secondary">
          <th>Provisions et dépenses en entretien routier</th>
          <template v-if="cas.Provision_depenses_entretien_routier">
            <td class="result-value" 
                v-for="(value, pdkey) in cas.Provision_depenses_entretien_routier"
                :key="pdkey">{{ Math.round(value.montant).toLocaleString() }}</td>
          </template>
        </tr>
        <tr>
          <!-- Une ligne de separation-->
          <td colspan="11"></td>
        </tr>
        <tr>
          <th class="result-title tht-blue">Dépenses totales</th>
          <template v-if="cas.cash_flow">
            <td class="tht-blue"
                v-for="(value, dtkey) in cas.cash_flow"
                :key="dtkey">0</td>
          </template>
          <template v-else>
            <td class="tht-blue"
                v-for="value in 10"
                :key="value">0</td>
          </template>
        </tr>
        <tr>
          <!-- Une ligne de separation-->
          <td colspan="11"></td>
        </tr>
        <!-- <tr>
          <th class="result-title tht-dark-blue">Recettes gare de péage</th>
          <template v-if="cas.cash_flow">
            <td class="result-value" 
                v-for="(value, rpkey) in cas.cash_flow"
                :key="rpkey">0</td>
          </template>
          <template v-else>
            <td class="result-value" 
                v-for="value in 10"
                :key="value">0</td>
          </template>
        </tr> -->
        <tr class="mt-1">
          <th class="result-title tht-dark-blue">Fonds levé</th>
          <template v-if="cas.Fonds_leve">
            <td class="result-value" 
                v-for="(fond, fkey) in cas.Fonds_leve"
                :key="fkey">{{ Math.round(Number(fond.montant)) }}</td>
          </template>
        </tr>
        <!-- <tr>
          <th class="result-title tht-dark-blue">Ressources additionnelles</th>
          <template v-if="cas.cash_flow">
            <td class="result-value" 
                v-for="(value, rakey) in cas.cash_flow"
                :key="rakey">0</td>
          </template>
        </tr> -->
        <tr>
          <!-- Une ligne de separation-->
          <td colspan="11"></td>
        </tr>
        <tr>
          <th class="tht-blue">Ressouces totales</th>
          <template v-if="cas.Fonds_leve">
            <td class="tht-blue"
                v-for="(fond, fkey) in cas.Fonds_leve"
                :key="fkey">{{ Math.round(Number(fond.montant)) }}</td>
          </template>
        </tr>
        <tr>
          <!-- Une ligne de separation-->
          <td colspan="11"></td>
        </tr>
        <!-- <tr>
          <th class="result-title tht-dark-blue">Fonds propres</th>
          <template v-if="cas.cash_flow">
            <td class="result-value" 
                v-for="(value,key) in cas.cash_flow"
                :key="key">{{Math.round(value.montant).toLocaleString()}}</td>
          </template>
          <template v-else>
            <td class="result-value" 
                v-for="value in 10"
                :key="value">0</td>
          </template>
        </tr>
        <tr>
          <th class="result-title tht-dark-blue">Report à nouveau</th>
          <template v-if="cas.cash_flow">
            <td class="result-value" 
                v-for="(value,key) in cas.cash_flow"
                :key="key">{{Math.round(value.montant).toLocaleString()}}</td>
          </template>
          <template v-else>
            <td class="result-value" 
                v-for="value in 10"
                :key="value">0</td>
          </template>
        </tr> -->
        <tr v-if="cas.cash_flow">
          <th class="result-title tht-dark-blue">Cash flow</th>
          <td class="result-value" 
              v-for="(cashflow, ckey) in cas.cash_flow"
              :key="ckey">
            {{ Math.round(cashflow.montant).toLocaleString() }}
          </td>
        </tr>
        <tr v-else>
          <th class="result-title tht-dark-blue">Cash flow</th>
          <td class="result-value" 
              v-for="value in 10"
              :key="value">0</td>
        </tr>
        <!-- <tr>
          <th class="result-title tht-dark-blue">Cash flow cumulés(cash flow + report à nouveau)</th>
          <template v-if="cas.cash_flow">
            <td class="result-value" 
                v-for="(value,key) in cas.cash_flow"
                :key="key">{{Math.round(value.montant).toLocaleString()}}</td>
          </template>
          <template v-else>
            <td class="result-value" 
                v-for="value in 10"
                :key="value">0</td>
          </template>
        </tr> -->
        <tr v-if="cas.cash_flow_actualise">
          <th class="result-title tht-dark-blue">Cash flow actualisé</th>
          <td class="result-value" 
              v-for="(cash_flow, cfakey) in cas.cash_flow_actualise"
              :key="cfakey">
            {{ Math.round(cash_flow.montant).toLocaleString() }}
          </td>
        </tr>
        <tr v-else>
          <th class="tht-dark-blue">Cash flow actualisé</th>
          <td class="result-value" 
              v-for="value in 10"
              :key="value">0</td>
        </tr>
      </tbody>
    </table>
    <!-- Le pied de page-->
  </div>
</template>

<script>
export default {
  name: "ResultatRessourcesDepenses",
  data: () => ({
    services: "",
    commissions: "",
    cas: {
      Achats: [],
      Autres_amortis: [],
      Autres_charges: [],
      Charges_de_personnels: [],
      Entretien_courants: [],
      Provisions_pour_entretien_periodique_necessaires: [],
      Provisions_pour_entretien_periodique_part_du_FER: [],
      Remplacement_des_vehicules_amortis: [],
      Renouvellement_des_ordinateurs_amortis: [],
      Renouvellement_des_telephones_fixes_amortis: [],
      Renouvellement_du_mobilier_amortis: [],
      SEAs: [],
      SEBs: [],
      TRI: [],
      Transports: [],
      VAN: [],
      cash_flow: [],
      cash_flow_actualise: []
    }
  }),
  props: ["servi", "comm", "cash", "nb_col"],
  watch: {
    servi() {
      if (this.servi) {
        this.services = this.servi
        console.log(this.services)
      }
    },
    comm() {
      if (this.comm) {
        this.commissions = this.comm
      }
    },
    cash() {
      if (this.cash) {
        this.cas = this.cash
      }
    },
    nb_col() {
      if (this.nb_col != this.cash.cash_flow.length) {
        this.cas.Achats = []
        this.cas.Autres_amortis = []
        this.cas.Autres_charges = []
        this.cas.Charges_de_personnels = []
        this.cas.Entretien_courants = []
        this.cas.Provisions_pour_entretien_periodique_necessaires = []
        this.cas.Provisions_pour_entretien_periodique_part_du_FER = []
        this.cas.Remplacement_des_vehicules_amortis = []
        this.cas.Renouvellement_des_ordinateurs_amortis = []
        this.cas.Renouvellement_des_telephones_fixes_amortis = []
        this.cas.Renouvellement_du_mobilier_amortis = []
        this.cas.SEAs = []
        this.cas.SEBs = []
        this.cas.Transports = []
        this.cas.cash_flow = []
        this.cas.cash_flow_actualise = []
        for (let index = 0; index < this.nb_col; index++) {
          this.cas.Achats.push(this.cash.Achats[index])
          this.cas.Autres_amortis.push(this.cash.Autres_amortis[index])
          this.cas.Autres_charges.push(this.cash.Autres_charges[index])
          this.cas.Charges_de_personnels.push(this.cash.Charges_de_personnels[index])
          this.cas.Entretien_courants.push(this.cash.Entretien_courants[index])
          this.cas.Provisions_pour_entretien_periodique_necessaires.push(this.cash.Provisions_pour_entretien_periodique_necessaires[index])
          this.cas.Provisions_pour_entretien_periodique_part_du_FER.push(this.cash.Provisions_pour_entretien_periodique_part_du_FER[index])
          this.cas.Remplacement_des_vehicules_amortis.push(this.cash.Remplacement_des_vehicules_amortis[index])
          this.cas.Renouvellement_des_ordinateurs_amortis.push(this.cash.Renouvellement_des_ordinateurs_amortis[index])
          this.cas.Renouvellement_des_telephones_fixes_amortis.push(this.cash.Renouvellement_des_telephones_fixes_amortis[index])
          this.cas.Renouvellement_du_mobilier_amortis.push(this.cash.Renouvellement_du_mobilier_amortis[index])
          this.cas.SEAs.push(this.cash.SEAs[index])
          this.cas.SEBs.push(this.cash.SEBs[index])
          this.cas.Transports.push(this.cash.Transports[index])
          this.cas.cash_flow.push(this.cash.cash_flow[index])
          this.cas.cash_flow_actualise.push(this.cash.cash_flow_actualise[index])
        }
      }
    }
  }
}
</script>

<style scoped>
table {
  font-size: 15px;
}
table,
th,
td {
  border: 1px solid black;
}
</style>
