<template>
  <div class="row mt-3">
    <!--<div class="row mb-3">
       <div class="text-left col-12">
        <router-link :to="'/analyses/board'">
          Analyses >
        </router-link>
        <router-link :to="'/analyses/bplan'">
          Business plan >
        </router-link>
        <span>Ajouter business plan</span>
      </div> 
    </div>
    <div >
      <h3 class="text-center border border-1">AJOUTER BUSINESS PLAN</h3>
    </div>
    <h4 class="text-uppercase bg-info text-center ">Tableau des flux de tresorerie</h4>-->

    <ResultatEntete :ent="entete"
                    @nba="nn => (nb_year = nn)" />
    <!-- <DetteCommission /> -->
    <RessourcesDepenses :cash="flow"
                        :nb_col="nb_year" />
    <!-- <div class="row mx-auto pt-3">
      <router-link :to="{ name: 'Analysebord' }"
                   class="btn btn-info btn-lg btn-block">
        Retour
      </router-link>
    </div> -->
  </div>
</template>
<script>
import ResultatEntete from "./resultatsComponents/ResultatEntete"
// import DetteCommission from "./resultatsComponents/ResultatServicedetteCommission"
import RessourcesDepenses from "./resultatsComponents/ResultatDepensesInvestissement"
export default {
  name: "Resultats",
  components: {
    ResultatEntete,
    // DetteCommission,
    RessourcesDepenses
  },
  data: () => ({
    entete: {
      van: "",
      tri: "",
      date_proj: "",
      nb_annee_proj: "",
      service: "",
      commission:"",
      fond_propre: "",
      taux_actualisation:"",
      dette: ""
    },
    serv: [],
    com: [],
    flow: "",
    nb_year: ""
  }),
  props: ["resultat", "servcom", "donnee_hypothese"],
  watch: {
    // servcom() {
    //   if (this.servcom) {
    //     this.serv = []
    //     this.com = []
    //     console.log(this.servcom)
    //     this.servcom.forEach(result => {
    //       if (Array.isArray(result)) {
    //         result.forEach(res => {
    //           this.serv.push(res.services)
    //           this.com.push(res.commission)
    //         })
    //       }
    //     })
    //   }
    // },
    resultat() {
      if (this.resultat) {
        this.entete.tri = this.resultat.TRI.TRI
        this.entete.van = this.resultat.VAN.VAN
        this.entete.date_proj = this.resultat.entete.Date_debut_projection.split("-")
        this.entete.dette = this.resultat.entete.Dettes
        this.entete.nb_annee_proj = this.resultat.entete.Nombre_annee_de_projection
        this.entete.service = this.resultat.entete.Service_de_la_dette
        this.entete.commission = this.resultat.entete.Commission_sur_LF
        this.entete.fond_propre = this.resultat.entete.Fonds_propres
        this.entete.taux_actualisation = this.resultat.entete.Taux_actualisation_des_flux_tresorerie
        this.flow = this.resultat
        this.nb_year = this.resultat.cash_flow.length
        
      }
    }
  }
}
</script>
